.utilizacao {
    @include flexbox();
    @include align-items('center');
    background: white;
    height: 133px;
    margin-bottom: 3px;
    padding: 15px;

    &_description{
        font-size: 1rem;
        margin-bottom: 0;
    }

    img {
        width: 90%;
    }

    > div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 74px;
        margin: 7px;
        flex-direction: column;  /* make main axis vertical */
        justify-content: center;
        
    }

    @media screen and (max-width:767px) {
        height:100px;
    }
}

.utilizacao_wrapper {
    margin-right: 0;
    margin-left: 0;
    
    & > [class^="col-"],
    & > [class*=" col-"] {
        padding-right: 2px;
        padding-left: 2px;
    }
}


.quadro {

    &_titulo {
        color:$purple;
        letter-spacing: 3px;
    }

    &_subtitulo {
        color:$purple;
        font-weight: 300;
        margin-bottom: 30px;
    }

    box-shadow: 0px -4px 8px 2px #f1f1f166;
    padding: 51px 57px 24px;
    margin: 0 0px -56px;
    position: relative;
    background: white;

    &_home_item {
        @include flexbox();
        @include align-items('center');
        background: #fff;
        height: 90px;
        margin-bottom: 3px;
    }

    &_home_item div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 52px;
        flex-direction: column;
        justify-content: center;
    }

    .btn-c {
        padding: 22px 0;
        margin: 14px 0 -59px;
    }

    .desc {
        margin-bottom: 0;
    }

    @media screen and (max-width:991px) {
        .btn-c {
            margin: 15px 0 0px;
        }
    }

    @media screen and (max-width: 767px) {

        padding: 33px 35px 24px;

        &_home_item {
            height: 60px;
        }

        &_titulo {
            font-size: 1.6rem;
        }

        &_subtitulo {
            font-size: 1.0rem;
        }
 
    }

}

.depoimento {

    &_item {
        padding: 36px 50px;
        margin: 5px 5px;
        background: #f5f5f5;
        box-shadow: 0px 1px 7px 0px #00000024;
        
    }

    &_item_description {
        font-style: italic;
        color:$purple;
        font-size: 1.2rem;
        text-align: center;
        letter-spacing: 2px;
    }

    &_item_autor {
        display: block;
        text-align: center;
        font-weight: 800;
        letter-spacing: 2px;
        font-size: .7rem;
        color:$pink;
    }

    &_item_empresa {
        color: $textColorDefault;
        display: block;
        text-align: center;
        font-weight: 300;
        letter-spacing: 1px;
    }

}

.form-contato {

    margin: -92px 0 -43px;
    position: relative;
    z-index: 1;

    input[type='submit'] {
        border: 0;
        display: block;
        width: 100%;
        font-size: 1.1rem;
        margin: 5px 0;
        text-transform: uppercase;
        padding: 24px;
        font-weight: 500;
        letter-spacing: 2px;
    }

    @media screen and (max-width:991px){
        margin: 0 0 -43px
    }

}

.form-contato-box {

    padding: 30px 42px 22px;
    background: #fff;
    box-shadow: 0 1px 14px 3px #0000000d;

    .input_box {
        padding: 16px 0 0px 35px;
        font-weight: 300;
        font-style: italic;
        position: relative;
        font-size: 13px;
    }

    .input_box label {
        font-size: .9rem;
    }

    .input_box {
        &:hover input ~ .checkmark {
            background-color: #ccc;
        }

        input:checked ~ .checkmark {
            background-color: #2196F3;
        }

        input:checked ~ .checkmark:after {
            display: block;
        }
    
        input:checked ~ .checkmark {
            background-color: #bcbec0;
        }
    }

    h4 {
        color:$purple;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 1.2px;
        margin-bottom: 18px;
    }

    input[type='text'] {
        border: 1px solid #bcbec0;
        width: 100%;
        padding: 13px 13px;
        margin: 0 0 16px;
        color:#ccc;
        background: #fff;
        letter-spacing: 2px;
    }

    input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;   
    }

    .checkmark {
        position: absolute;
        top: 10px;
        left: 0;
        height: 27px;
        width: 27px;
        border: 1px solid #bcbec0;
        border-radius: 50%;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .checkmark:after {
        top: 6px;
        left: 6px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
    }

    @media screen and (max-width: 1199px) {
        input[type='text'] {
            margin: 0 0 10px;
        }
       
    }
      


}

.card {
    border-radius: 0;
    border:initial;
    margin: -33px 0 -71px;
    padding: 37px 37px 21px;
    box-shadow: 0 1px 14px 3px #0000000d;

    &_title {
        color:$pink;
        letter-spacing: 1px;
    }

    &_subtitle {
        color:$purple;
        font-weight: 300;
        font-size: 1.3rem;
        letter-spacing: 1px;
    }

    &_itens {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
        @include flexbox();
        @include align-items(center);
        flex-direction: column;
    }

    &_itens > div {
        min-height: 60px;
        width: 100%;
        flex:1;
        @include flexbox();
        @include align-items(center);
    }

    &_itens .card_ico {
        margin-right:20px;
    }

    &_itens .card_desc {
        flex:1;
        color: $textColorDefault;
    }

    &_itens .card_ico > img {
        width:36px;
    }

    @media screen and (max-width:1199px) {

        &_title {
            font-size: 1.5rem;
        }

        &_subtitle {
            font-size: 1rem;
        }

        &_desc {
            font-size: 0.9rem;
        }

        &_itens .card_ico > img {
            width: 26px;
        }

    }

    @media screen and (max-width:991px) {
        min-height: 480px;
    }

    @media screen and (max-width:767px) {

        margin: -20px 0 35px;
        min-height: initial;
    }

}

.faixa {
    @include flexbox();
    @include align-items(center);
    margin-bottom: 10px;
    padding: 16px 18px;
    box-shadow: 0 1px 14px 3px #0000000d;
    background-color: white;
    min-height: 78px;

    &_ico {
        margin-right: 23px;
    }

    &_description {
        display: block;
        color:#606469;
        font-weight: 300;
    }
}

.faq {
    color: #fff;
    position: relative;
    margin: 45px 0 -23px;
    z-index:3;
    background: #fff;
    padding: 24px;
    box-shadow: 0 1px 14px 3px #0000000d;
    min-height: 115px;

    &_pergunta {
        display: block;
        color:$purple;
        font-weight: 800;
    }

    &_resposta {
        display: block;
        color: #606469;
        font-weight: 300;
        font-style: italic;
        font-size: 1rem;
    }
}

.plano-item {

    list-style-type: none;
    padding-left: 29px;

    > li:before {
        position: absolute;
        width:10px;
        height: 10px;
        background:$green;
        content: '';
        top: 8px;
        left: -25px;
        border-radius: 50%;
    }

    > li {
        color: #606469;
        font-size: 1.1rem;
        min-height: 50px;
        margin-bottom: 9px;
        position: relative;
    }

}

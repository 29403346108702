$green: #00ffce;
$red: #ff0031;
$pink: #ed1e3f;
$purple: #390969;
$purple2: #270043;

$textColorDefault: #768288;


$nunito: 'Nunito Sans', sans-serif;
$roboto: 'Roboto', sans-serif;


.navbar {
    
    .navbar-nav > .nav-item.active > a.nav-link {
        color:$pink;
    }

    .nav-login {
        background:$purple;
        color:white !important;
    }

    .navbar-toggler {
        margin-right: 15px;
    }

    .navbar-nav > .nav-item > a {
        color:$purple;
        font-size: 0.9rem;
        font-weight: 800;
        text-transform: uppercase;   
        padding: 12px 20px;
        @include transition(color 0.6s);
    }

    .navbar-nav > .nav-item > a:hover {
        color:$pink;
    }

    @media screen and (max-width:991px) {

        #navbarResponsive {
            margin-top: 25px;
        }

        .nav-login {
            background: initial;
            color:initial;
        }

        .navbar-brand {
            padding-left: 15px;
        }
    }

        
}

footer {
    padding: 50px 0 27px;
    background:$purple;
    position: relative;

    .container {
        max-width: 1400px;
    }

    .rights {
        color:white;
        display: block;
        font-style: italic;
        font-size: 0.9rem;
    }

    .btn-fale-conosco {
        padding: 8px 29px;
    }

    .btn-fale-conosco img {
        margin-right: 13px;
        width: 35px;   
    }

    .btn-fale-conosco-desktop{
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .btn-fale-conosco-mobile {
        display:none;
        text-align: center;
        margin: 20px auto 0;
        width: 233px;
    }

    .btn-fale-conosco-desktop,
    .btn-fale-conosco-mobile {
        @extend .btn-fale-conosco
    }

    @media screen and (max-width: 991px) {

        padding: 50px 0 0;

        .logo-footer {
            text-align: center;
            display: block;
            margin: 16px auto 20px; 
        }

        .btn-fale-conosco-desktop {
            display:none;
        }

        .btn-fale-conosco-mobile {
            display: block;
        }
    }

}

.section1 {

    &_title {
        margin-bottom: 30px;
    }

    @media screen and (max-width:767px) {

        &_title {
            font-size: 1.6rem;
        }
        
    }

}

.navbar {
    padding: 26px 0px 44px;
}

.section_topo_preco {

    background-image: url(../images/img_topo_preco.jpg);
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-size: cover;

    .title {
        font-weight: 800;
        margin-bottom: 0px;
    }

    .subtitle {
        color: #390969;
        font-size: 2.4rem;
    }

    .btn-c span {
        display: block;
        font-size: 2.9rem;
        font-weight: 800;
    }

    .btn-ct1 {
        font-size: 2.5rem;
        padding: 17px 0 18px;
        
    }

    .btn-c {
        margin: 0 auto 6px;
        display: block;
        max-width: 493px;
        
        
        line-height: 3rem;
    }

    .btn-ct3 {
        font-size: 1.5rem;
        text-transform: uppercase;
    }

    .slogan {
        text-transform: uppercase;
        display: block;
        font-weight: 800;
        font-size: 1.4rem;
        color: #606469;
        margin: 12px 0 30px;
    }

    .slogan2 {
        color:$red;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 1.4rem;
        margin-top: 19px;
        margin-bottom: 9px;
        display: block;
    }

    .slogan3 {
        font-size: 1.4rem;
        display: block;
        margin-bottom: 22px;
        color:#606469;
    }

    @media screen and (max-width: 991px){
        .subtitle {
            font-size: 1.7rem;
        }

        .btn-c {
            max-width: initial;
            line-height: 2.5rem;
        }

        .btn-ct1 {
            font-size: 1.9rem;
        }

        .btn-c span {
            font-size: 2.2rem;
        }

        .btn-ct3 {
            font-size: 1.1rem;
        }
    }

    @media screen and (max-width: 767px){
        background:none;
    }

    
}

.section_home_quadro {

    background: url('../images/bg_topo.png') no-repeat;
    background-position-y: 34px;
    background-position-x: 72%;
    background-size: 900px;
    background-repeat: no-repeat; 

    .top-desc{
        font-size: 1.2rem;
        margin-bottom: 17px;
    }

    .img_topo {
        width: 77%;
    }

    @media screen and (max-width:1199px) {
        background-position-y: 50px;
        background-position-x: 106%;
        background-size: 869px;
    }

    @media screen and (max-width:991px) {
        background-position-x: 100%;
    }

    @media screen and (max-width:767px){
        background:none;
    }
    
}

.section_home_utilizacoes {

    background: url('../images/bg_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 130px 0 85px;

    &_title {
        color:white;
    }
    
    @media screen and (max-width:767px){
        padding: 130px 0 43px;
    }

}

.section-home-depoimentos {

    padding: 49px 0 70px;

    h2 {
        color:$purple;
        margin-bottom: 30px;
    }

    .container {
        max-width: 1400px;
    }

}

.section-contato {

    background: #f5f5f5;
    padding: 41px 0px 0;

    h2 {
        color:$purple;
    }

    p {
        color: #606469;
        margin: 20px 0 22px;
    }

    @media screen and (max-width:991px) {
        img {
            text-align: center;
            display: block;
            margin: auto;
        }
    }

}

.section-top-produto {

    .description {
        font-size: 1.6rem;
        text-align: center;
        display: block;
        line-height: 34px;
        margin-bottom: 40px;
    }

    @media screen and (max-width:991px) {
        .title {
            font-size: 1.8rem;
        }

        .description {
            font-size: 1.1rem;
            line-height: initial;
        }
    }
    
}

.section-top-quadros {
    background: #f5f5f5;
}

.section-descritivo {
    
    padding: 143px 0;
    background: url(../images/bg_1.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    .container {
        position: relative;
    }

    .title {
        color: #fff;
        font-size: 2.2rem;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .description {
        color: #fff;
        font-size: 1.2rem;
    }

    .btn-c {
        max-width: 581px;
        display: block;
        margin: 32px auto;
        padding: 21px 0;
        letter-spacing: 1px;
        font-weight: 600;
    }

    @media screen and (max-width:991px) {
        padding: 123px 0 25px;

        .title {
            font-size: 1.9rem;
        }

        .description {
            font-size: 1.1rem;
        }

        .btn-c {
            padding: 14px 10px;
            font-size: 0.8rem;      
        }
    }

    @media screen and (min-width:992px) {

        .container:after {
            background: url(../images/img_dog.png) no-repeat;
            position: absolute;
            bottom: -108px;
            right: 0;
            width:219px;
            height:245px;
            content: '';
        }

    }

    @media screen and (max-width:767px){
        
        padding: 43px 0 25px;

    }

}

.section-preco-descritivo {

    .container {
        position: relative;
        max-width: 1345px;
    }

    padding: 120px 0 0;
    background: url(../images/bg_1.jpg);
    background-size: cover;
    background-repeat: no-repeat;


    .title {
        color: $pink;
        margin-bottom: 34px;
        font-weight: 300;
        font-size: 2.5rem;
        letter-spacing: 1.3px;
    }

    .btn-c {
        font-size: 1.7rem;
        display: block;
        max-width: 588px;
        text-align: center;
        padding: 12px 0 10px;
        margin: 0 auto -19px;
    }

    .slogan {
        color: white;
        font-size: 1.7rem;
        margin-bottom: 55px;
        display: block;
        letter-spacing: 1px;
    }

    @media screen and (max-width:767px) {
        .title br {
            display: none;
        }

    }

    @media screen and (max-width:991px) {
        
        .title {
            font-size: 1.9rem;
        }

        .slogan {  
            font-size: 1.3rem;
        }

    }

    @media screen and (min-width:992px){
        
        .container:after {
            position: absolute;
            bottom:0;
            right:0;
            content: '';
            background: url(../images/img_dog2.png) no-repeat;
            width:219px;
            height:331px;
        }

    }

}

.section-plano {

    padding: 76px 0 0;

    h2 {
        color: $purple;
    }

    .slogan {
        color:$pink;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 1.5rem;
        margin-top: 19px;
        margin-bottom: 9px;
        display: block;
    }

    .slogan-desc {
        color:#606469;
        font-weight: 300;
        font-size: 1.1rem;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 28px;
    }

    .btn-c {
        padding: 14px 0;
        font-size: 1.3rem;
        max-width: 451px;
        margin: 0 auto -30px;
        letter-spacing: 1px;
    }

}

.section-faq {
    
    background:#f5f5f5;
    padding: 80px 0 0px;
    
    h2 {
        color:$purple;
        margin-bottom: 0;
    }

}

.row-faixas {
    position: relative;
    z-index: 1;
    margin-bottom: -53px;

    .custom-gutter {
        padding-left:4px;
        padding-right: 4px;
    }

    @media screen and (max-width:991px){
        .custom-gutter {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
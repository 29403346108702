img {max-width: 100%;}

a:hover,
a:focus,
a:active,
a:visited {
    text-decoration: none;
}

div.wpcf7 input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

body {
    font-family: $nunito;
    font-weight: 300;
}


h1,h2 {
    text-transform: uppercase;
    font-weight: 300;
}

h1 {
    color:$purple;

    @media screen and (max-width:767px) {
        font-size: 2.0rem;
    }
}



b {
    font-weight: 800;
}

p {
    font-family: $nunito;
    color: $textColorDefault;
}

.logotipo {
    max-width: 80%;
}

.btn-c img{
    margin-right: 7px;
    width: 22px;
}

.btn-ct1 {
    background: #00ffce;
    color: $purple2;
    display: block;
    text-align: center;
    @include transition(background 0.8s);

    &:hover{
        background:#00ffba;
        color: $purple2;
    }
}

.btn-ct2 {
    background: $red;
    color:white;
    display: block;
    text-align: center;
    @include transition(background 0.3s);

    &:hover{
        background:#ff0080;
        color:white;
    }
     
}

.btn-ct3 {
    background: $purple;
    color: $green;

    &:hover {
        color:$green;
    }
}

.btn-home-topo{
    width: 48%;
    float:left;
    vertical-align: middle;
    margin: 2px 7px 30px 0;
    padding: 10px 15px;
    font-weight: 800;
    background:white;
    @include transition(background 0.8s);
    

    img {
        float: left;
        margin: 2px 7px 0 0;
    }

    @media screen and (max-width:767px) {
        float:none;
        width:100%;
        display: block;
        text-align: center;
        margin: 2px 7px 11px 0;
        img {
            float:none;
        }
    }
}

.btn-home-topo.btn-topo1 {
    font-size: 12px;
    border: 1px solid $purple;
    color: $purple;
    
    &:hover {
        background: #390969bd;
        color:white;
    }
}

.btn-home-topo.btn-topo2 {
    padding: 11.2px 15px;
    text-align: center;
    line-height: 32px;
    vertical-align: text-top;
    font-size: 11px;
    border: 1px solid $pink;
    color: $pink;

    &:hover {
        background: #ed1e3fc4;
        color:white;
    }
    
}

.background-opacity {
    position: relative;
    &:before {
        background:#fff0;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        content: '';
    }

    @media screen and (max-width:991px) {
        &:before {
            background: #fffffffa;
        }
    }
}